import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import ReactPlayer from "react-player";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { Skeleton } from "@tbml/components/Skeleton";
import { Spacer } from "@tbml/components/Spacer";
import { StoryInsideTopic } from "@tbml/api-interface/graphql";
import { Caption } from "@tbml/components/Typography";
import { ImageModal } from "@tbml/components/ImageModal";
import {
  ClampedStoryTitle,
  StoryPreviewBody,
  ResponsiveReactPlayer,
  CARD_WIDTH,
  MEDIA_HEIGHT,
  StoryPreviewAlignment,
  MEDIA_WIDTH,
  PlayerWrapper,
} from "./styles";
import { StoryPreviewOverlay } from "./Overlay";
import { StoryCardMedia } from "./StoryCardMedia";
import { BroadcastMediaModal } from "./BroadcastMediaModal";

export type Props = {
  story: StoryInsideTopic;
  hoveredOverride?: boolean;
  priority?: boolean;
  loading?: boolean;
  align?: StoryPreviewAlignment;
  showStoryOnly?: boolean | null;
};

function StoryPreviewSkeletonComponent({
  hoveredOverride = false,
  isVerticalLayout,
}: Pick<Props, "hoveredOverride"> & { isVerticalLayout: boolean }) {
  return (
    <Stack aria-busy title="Loading story...">
      <StoryPreviewOverlay
        hoveredOverride={hoveredOverride}
        isVerticalLayout={isVerticalLayout}
        stopHoverEffect={false}
      >
        {({ hovered }) => (
          <>
            <Box sx={{ flex: "1 1 auto" }} gap={1}>
              <Skeleton forText="h4" width="100%" />
              <Spacer size="verticalXs" />
              <Skeleton forText="shortTextDefault" width="100%" />
              <Skeleton forText="shortTextDefault" width="100%" />
              <Skeleton forText="shortTextDefault" width="100%" />
              <Skeleton forText="shortTextDefault" width="40%" />
            </Box>
            <Box
              sx={{
                flex: "0 0 auto",
                width: isVerticalLayout ? "auto" : MEDIA_WIDTH,
              }}
            >
              <StoryCardMedia
                width={CARD_WIDTH}
                height={MEDIA_HEIGHT}
                hovered={hovered}
                loading
                alt="Loading story..."
              />
            </Box>
          </>
        )}
      </StoryPreviewOverlay>
    </Stack>
  );
}

const computeSource = (originPath?: string | null) => {
  if (!originPath) return undefined;
  if (originPath.endsWith(".gif")) return originPath;

  return `${originPath}?auto=enhance&fit=crop&crop=faces,edges`;
};

export const StoryPreview: React.FC<Props> = ({
  story,
  hoveredOverride,
  priority,
  showStoryOnly,
}) => {
  const [imageLoadError, setImageLoadError] = useState<boolean>();
  const {
    title,
    editorial,
    image,
    broadcastData,
    actionButtonLink,
    actionButtonName,
    actionButtonIncluded,
  } = story;
  const theme = useTheme();
  const isVerticalLayout = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StoryPreviewSkeleton = useCallback(
    () => (
      <StoryPreviewSkeletonComponent
        hoveredOverride={hoveredOverride}
        isVerticalLayout={isVerticalLayout}
      />
    ),
    [hoveredOverride, isVerticalLayout]
  );
  const { withSkeleton } = useSkeleton({
    Skeleton: StoryPreviewSkeleton,
  });

  useEffect(() => {
    if (imageLoadError) {
      setImageLoadError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const articlesCount = story?.articles?.length;
  const storyStats =
    !showStoryOnly &&
    articlesCount &&
    `${articlesCount} article${articlesCount > 1 ? "s" : ""}`;

  const [overlayIsOpen, setOverlayIsOpen] = useState(false);
  const [isBroadcastOverlayOpen, setIsBroadcastOverlayOpen] = useState(false);
  const [startingTime, setStartingTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const imageSrc = computeSource(image?.originPath);
  const isAudio = broadcastData?.url?.includes(".mp3") ?? false;
  const playerRef = useRef<ReactPlayer>(null);
  const shouldPlay = !!image && !imageLoadError;

  return withSkeleton(
    <Stack title={`Preview: ${title}`}>
      <StoryPreviewOverlay
        hoveredOverride={hoveredOverride}
        isVerticalLayout={isVerticalLayout}
        stopHoverEffect={!!showStoryOnly}
      >
        {({ hovered }) => (
          <>
            <Box sx={{ flex: "1 1 auto" }} gap={1}>
              {!!storyStats && (
                <Caption color="textSecondary">{storyStats}</Caption>
              )}
              <ClampedStoryTitle hovered={hovered}>{title}</ClampedStoryTitle>
              <StoryPreviewBody
                editorialContent={editorial}
                font="shortTextLarge"
              />
              {actionButtonIncluded && actionButtonLink && (
                <Button
                  variant="contained"
                  target="_blank"
                  component="a"
                  href={actionButtonLink}
                  style={{ maxWidth: "fit-content" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {actionButtonName}
                </Button>
              )}
            </Box>
            {broadcastData?.publicUrl && (
              <Box
                aria-label="broadcast-media"
                sx={{
                  flex: "0 0 auto",
                  width: isVerticalLayout ? "auto" : MEDIA_WIDTH,
                  position: "relative",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  // if it's audio, we don't show overlay
                  if (isAudio) {
                    return;
                  }
                  setIsBroadcastOverlayOpen(!isMobile);
                  if (playerRef.current) {
                    const currentTime = playerRef.current.getCurrentTime();
                    setStartingTime(currentTime);
                    setPlaying(!playing);
                    playerRef.current.getInternalPlayer().pause();
                  }
                  if (!playing && isMobile) {
                    playerRef.current?.getInternalPlayer().play();
                    setPlaying(!playing);
                  }
                }}
              >
                <ResponsiveReactPlayer
                  ref={playerRef}
                  // this is added to preload 0.4s of a video in order to show a default thumbnail on Safari (Destkop/iOS)
                  url={`${broadcastData.publicUrl}#t=0.4`}
                  width={image ? `${CARD_WIDTH}px` : "100%"}
                  height={image ? `${MEDIA_HEIGHT}px` : "100%"}
                  wrapper={
                    broadcastData.url?.includes(".mp4")
                      ? PlayerWrapper
                      : undefined
                  }
                  controls
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        preload: "metadata",
                      },
                      forceAudio: isAudio,
                    },
                  }}
                  playing={shouldPlay}
                  playsinline
                  light={
                    image && !imageLoadError ? (
                      <StoryCardMedia
                        src={imageSrc}
                        width={CARD_WIDTH}
                        height={MEDIA_HEIGHT}
                        alt="Image title"
                        hovered={hovered}
                        priority={priority}
                        placeholder="blur"
                        onError={() => {
                          setImageLoadError(true);
                        }}
                        showOriginalSize={image?.showOriginalSize}
                      />
                    ) : (
                      false
                    )
                  }
                  ratio={isAudio ? 21 / 3 : 16 / 9}
                />
              </Box>
            )}
            {!broadcastData?.publicUrl && image && (
              <Box
                sx={{
                  flex: "0 0 auto",
                  width: isVerticalLayout ? "auto" : MEDIA_WIDTH,
                }}
              >
                <StoryCardMedia
                  src={imageSrc}
                  width={CARD_WIDTH}
                  height={MEDIA_HEIGHT}
                  alt="Image title"
                  onClick={(event) => {
                    event.preventDefault();
                    setOverlayIsOpen(!isMobile);
                  }}
                  hovered={hovered}
                  priority={priority}
                  placeholder="blur"
                  onError={() => setImageLoadError(true)}
                  showOriginalSize={image?.showOriginalSize}
                />
              </Box>
            )}
          </>
        )}
      </StoryPreviewOverlay>
      {image && imageSrc && (
        <ImageModal
          image={image}
          isOpen={overlayIsOpen}
          setIsOpen={setOverlayIsOpen}
          src={imageSrc}
        />
      )}
      {broadcastData?.publicUrl && (
        <BroadcastMediaModal
          startingTime={startingTime}
          isOpen={isBroadcastOverlayOpen}
          setIsOpen={setIsBroadcastOverlayOpen}
          src={broadcastData.publicUrl}
          isAudio={isAudio}
          onClose={(playbackTime) => {
            if (playerRef.current) {
              playerRef.current.seekTo(playbackTime, "seconds");
              setStartingTime(playbackTime);
            }
          }}
        />
      )}
    </Stack>
  );
};
