import React, { useEffect } from "react";
import Head from "next/head";
import { styled } from "@mui/system";
import { gql } from "graphql-request";
import {
  useSkeleton,
  SkeletonContext,
} from "@tbml/shared-dependencies/react-skeletons";
import { useCustomers } from "@tbml/hooks/useCustomers";
import { H4, Text } from "@tbml/components/Typography";
import { useCurrentCustomerId } from "@tbml/hooks/useCurrentCustomerId";
import { useIsInternalUser } from "@tbml/hooks/useIsInternalUser";
import tokens from "@tbml/tokens";
import { Spacer } from "@tbml/components/Spacer";
import {
  LicenseInfo,
  LicenseInfoSkeleton,
} from "@tbml/components/LicenseExpired/LicenseInfo";
import { DEFAULT_CUSTOMER_HAS_CUSTOM_FOOTER } from "@tbml/api-interface/customer";
import { useSyncCurrentIssueIdState } from "../../state/currentIssueId";
import { Issue, IssueSkeleton } from "../Issue";
import { CustomerHeaderImage } from "../CustomerHeaderImage";
import { useHasCustomFooterState } from "../../state/hasCustomFooter";

export type Props = { loading?: boolean; issueId?: string };

function CustomerSkeleton() {
  return (
    <div aria-busy title="Loading customer...">
      <Head>
        <title>Briefings: Preparing...</title>
      </Head>
      <LicenseInfoSkeleton />
      <CustomerHeaderImage isLoading />
      <SkeletonContext.Provider value>
        <IssueSkeleton />
      </SkeletonContext.Provider>
    </div>
  );
}

const PaddedBox = styled("div")`
  padding: ${tokens.spacing.horizontalXxxl.value};
  text-align: center;
`;

export const customerPageCustomerFragmentName = "CustomerPageFields";
export const customerPageCustomerFragment = gql`
  fragment CustomerPageFields on Customer {
    id
    name
    language
    image {
      id
      originPath
      mediaWidth
      mediaHeight
      allowCropping
      showOriginalSize
    }
    defaultIssueId
    executiveBriefingTopics {
      id
      title
      subTitle
    }
    inboxEbsId
    inboxAddcovId
    inboxSocialMediaId
    contactListIds
    contactInfoIncluded
    contactInfoName
    contactInfoEmail
    hasCustomFooter
    hiddenSortSectionsForAddcov {
      id
    }
  }
`;

export function Customer({
  loading = false,
  issueId: issueIdProp = undefined,
}: Props): JSX.Element | null {
  const { data: isInternalUser, isPending: internalUserLoading } =
    useIsInternalUser();
  const [customerId, setCustomerId] = useCurrentCustomerId();

  const { query } = useCustomers();
  const {
    data: customers,
    status,
    isPending: customerLoading,
  } = query({
    filter: customerId ? { ids: [customerId] } : {},
    fragmentName: customerPageCustomerFragmentName,
    fragment: customerPageCustomerFragment,
  });

  const [customer] = customers ?? [];
  const [, setHasCustomFooter] = useHasCustomFooterState();

  useEffect(() => {
    if (!customer) return;
    setHasCustomFooter(
      customer.hasCustomFooter ?? DEFAULT_CUSTOMER_HAS_CUSTOM_FOOTER
    );
  }, [customer, setHasCustomFooter]);

  useEffect(() => {
    if (!customer) return;
    if (customerId === customer.id) return;
    setCustomerId(customer.id);
  }, [setCustomerId, customer, customerId]);

  const { withSkeleton } = useSkeleton({
    isLoading:
      (!!customerId && !customer) ||
      internalUserLoading ||
      loading ||
      status === "pending",
    Skeleton: CustomerSkeleton,
  });

  const issueId = issueIdProp ?? customer?.defaultIssueId;
  useSyncCurrentIssueIdState(issueId);

  if (status === "error") {
    return <Text>Failed to fetch content.</Text>;
  }

  if (status === "pending") {
    return <CustomerSkeleton />;
  }

  if ((!customerLoading && !customer) || !issueId) {
    return (
      <div>
        <Head>
          <title>Briefings</title>
        </Head>
        {isInternalUser ? (
          <PaddedBox>
            <H4>
              Please provide a customer by selecting it in the customer
              selection tool
            </H4>
          </PaddedBox>
        ) : (
          <PaddedBox>
            <H4>It looks like no content has been created yet.</H4>
            <Spacer />
            <Text>
              Do you think this is a mistake? Contact us under{" "}
              <a href="mailto:contact@unicepta.com">contact@unicepta.com</a>
            </Text>
          </PaddedBox>
        )}
      </div>
    );
  }

  return withSkeleton(
    <LicenseInfo issueId={issueId}>
      <Head>
        <title>Briefings: {customer.name}</title>
      </Head>
      <CustomerHeaderImage customerHeaderImage={customer.image} />
      <Issue
        hiddenSortSectionsForAddcov={customer.hiddenSortSectionsForAddcov || []}
        id={issueId}
        defaultIssueId={customer.defaultIssueId}
        inboxSocialMediaProductId={customer.inboxSocialMediaId}
        contactInfoIncluded={customer.contactInfoIncluded}
        contactInfoEmail={customer.contactInfoEmail}
        contactInfoName={customer.contactInfoName}
        language={customer.language}
      />
    </LicenseInfo>
  );
}
