import * as React from "react";
import { Stack } from "@mui/material";

export type Props = {
  children: ({ hovered }: { hovered: boolean }) => React.ReactNode;
  hoveredOverride?: boolean;
  isVerticalLayout: boolean;
  stopHoverEffect: boolean;
};

export function StoryPreviewOverlay({
  children,
  hoveredOverride = false,
  isVerticalLayout,
  stopHoverEffect,
  ...props
}: Props): JSX.Element {
  const [hovered, setHovered] = React.useState(false);

  React.useEffect(() => {
    setHovered(!!hoveredOverride);
  }, [hoveredOverride]);

  return (
    <Stack
      {...props}
      alignItems={isVerticalLayout ? "normal" : "center"}
      direction={isVerticalLayout ? "column-reverse" : "row"}
      gap={2}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      {children({ hovered: stopHoverEffect ? false : hovered })}
    </Stack>
  );
}
