import * as React from "react";
import { Stack } from "@mui/material";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { useRouter } from "@tbml/shared-dependencies/router";
import { H3 } from "@tbml/components/Typography";
import { Spacer } from "@tbml/components/Spacer";
import { Skeleton } from "@tbml/components/Skeleton";
import { StoryInsideTopic } from "@tbml/api-interface/graphql";
import useScrollSection from "@tbml/components/ScrollSections/useScrollSection";
import { StoryPreview } from "../StoryPreview";
import { TopicContainer, StoryPreviewContainer } from "./styles";

export type Props = {
  title: string;
  subtitle?: string;
  stories: StoryInsideTopic[];
  firstTopic?: boolean;
  showStoryOnly?: boolean | null;
};

export function TopicSkeleton({
  stories,
}: {
  stories: StoryInsideTopic[];
}): JSX.Element {
  return (
    <div aria-busy title="Loading topic...">
      <TopicContainer>
        <Spacer size="verticalXxl" />
        <Skeleton forText="h3" width="30%" />
        <Skeleton forText="h4" width="50%" />
        <Spacer size="verticalXs" />
        <Spacer size="gutterS" />
        <Stack gap={6}>
          {stories?.map((story) => (
            <StoryPreview key={story.id} story={story} />
          ))}
        </Stack>
        <Spacer size="verticalXxl" />
      </TopicContainer>
    </div>
  );
}

export const Topic: React.FC<Props> = ({
  stories,
  title,
  subtitle,
  firstTopic,
  showStoryOnly,
  ...props
}) => {
  const {
    query: { issueId },
  } = useRouter();
  const MemoizedTopicSkeleton = React.useMemo(
    () =>
      function TopicSkeletonComponent() {
        return <TopicSkeleton stories={stories} />;
      },
    [stories]
  );
  const { withSkeleton } = useSkeleton({
    Skeleton: MemoizedTopicSkeleton,
  });

  const scrollSecionProps = useScrollSection();

  return withSkeleton(
    <div {...props} {...scrollSecionProps}>
      <TopicContainer>
        <Spacer size="verticalXl" />
        <H3>{title}</H3>
        <H3 color="textSecondary">{subtitle}</H3>
        <Spacer size="gutterS" />
        <Stack role="tablist" gap={6}>
          {stories.map((story) => {
            const content = (
              <StoryPreview
                key={story.id}
                story={story}
                priority={firstTopic}
                showStoryOnly={showStoryOnly}
              />
            );
            if (showStoryOnly) {
              return (
                <>
                  {content}
                  <Spacer />
                </>
              );
            }

            return (
              <StoryPreviewContainer
                key={story.id}
                href={`${issueId ?? ""}/stories/${story.id}`}
                animation="slideLeft"
                role="tab"
              >
                {content}
                <Spacer />
              </StoryPreviewContainer>
            );
          })}
        </Stack>
        <Spacer size="verticalXxl" />
      </TopicContainer>
    </div>
  );
};
